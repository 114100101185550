jQuery(document).ready(function($) {
	$('#show-more-cons').click(function(event) {
        $('.o-table__item--cons .o-table__more').addClass('is-visible');
        $('.o-table__item--cons .o-table__footer').addClass('is-hidden');
        
        event.preventDefault();
	});

	$('#show-more-pros').click(function(event) {
        $('.o-table__item--pros .o-table__more').addClass('is-visible');
        $('.o-table__item--pros .o-table__footer').addClass('is-hidden');
        
        event.preventDefault();
	});

	$('#show-more-pros-cons').click(function(event) {
        $('.o-table__more').addClass('is-visible');
        $('.o-table__expand').addClass('is-hidden');
        
        event.preventDefault();
	});
});
