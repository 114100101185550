jQuery(document).ready(function($) {
	$(".primary-nav__item.has-child").on({
		mouseenter: function () {
			$(this).addClass('is-active');
			$(this).find('.primary-nav__group').addClass('is-active');
            $(this).find('.primary-nav__child-item:first').addClass('is-active');
            $(this).find('.primary-nav__section-nav:first').addClass('is-active');
			var linkNavHeight = $(this).find('.primary-nav--level-1').height();
			var sectionNavHeight = $(this).find('.primary-nav__section-nav').height();
			if(linkNavHeight > sectionNavHeight) {
				var linkNavHeight = linkNavHeight + 20;
				$(this).find('.primary-nav__group-inner').css('height', linkNavHeight + 'px');
			} else {
				var sectionNavHeight = sectionNavHeight + 20;
				$(this).find('.primary-nav__group-inner').css('height', sectionNavHeight + 'px');
			}
		},
		mouseleave: function () {
			$(this).removeClass('is-active');
			$(this).find('.primary-nav__group').removeClass('is-active');
			$('.primary-nav__child-item').removeClass('is-active');
			$('.primary-nav__section-nav').removeClass('is-active');

			// remove dropdown height
			if ($(this).find('.primary-nav__section-nav').length) {
				$(this).find('.primary-nav__group-inner').css('height', 'auto');
			}
		}
	});
	$(".primary-nav__child-item").on({
		mouseenter: function () {
			$('.primary-nav__child-item').removeClass('is-active');
			$('.primary-nav__section-nav').removeClass('is-active');
			$(this).addClass('is-active');
			$(this).find('.primary-nav__section-nav').addClass('is-active');
		}
	});
});
