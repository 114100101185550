jQuery(document).ready(function($) {
	$('.mobile-nav__btn').click(function(event) {
		if ($(this).hasClass('is-active')) {
			$(this).removeClass('is-active');
			$(this).next('.mobile-nav__child').slideUp('normal');
		} else {
			$(this).addClass('is-active');
			$(this).next('.mobile-nav__child').slideDown('normal');
		}

        event.preventDefault();
	});

	$('.mobile-nav__expand-link').click(function(event) {
		if ($(this).hasClass('is-active')) {
			$(this).removeClass('is-active');
			$(this).next('.mobile-subnav').removedClass('is-active');
		} else {
			$(this).addClass('is-active');
			$(this).next('.mobile-subnav').addClass('is-active');
		}

        event.preventDefault();
	});

	$('.mobile-subnav__back-btn').click(function(event) {
        $('.mobile-nav__expand-link').removeClass('is-active');
        $('.mobile-subnav').removeClass('is-active');

        event.preventDefault();
	});    
});
