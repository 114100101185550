import Headroom from "headroom.js";

const reviewHeader = document.querySelector(".review-summary-bar");
const reviewComparison = document.querySelector(".review-comparison");
const reviewComparisonHeader = document.querySelector(".review-header__inner");

if (reviewHeader) {
	const fixedReviewHeader = new Headroom(reviewHeader, {
		offset : 600,
	    classes : {
	        initial : "review-header--headroom",
	        pinned : "review-header--pinned",
	        unpinned : "review-header--unpinned",
	        top : "review-header--top",
	        notTop : "review-header--not-top",
	        bottom : "review-header--bottom",
	        notBottom : "review-header--not-bottom",
	        frozen: "review-header--frozen",
	    },
		onTop : function() {
			var reviewElem = document.querySelector('.review-summary-bar');
			var reviewWrapElem = document.querySelector('.review-summary-bar-wrap');
			reviewWrapElem.style.minHeight = reviewElem.offsetHeight + 'px';
		},
	});

	fixedReviewHeader.init();
}

if (reviewComparison) {
	const fixedReviewComparisonHeader = new Headroom(reviewComparisonHeader, {
		offset : 700,
	    classes : {
	        initial : "review-comparison--headroom",
	        pinned : "review-comparison--pinned",
	        unpinned : "review-comparison--unpinned",
	        top : "review-comparison--top",
	        notTop : "review-comparison--not-top",
	        bottom : "review-comparison--bottom",
	        notBottom : "review-comparison--not-bottom",
	        frozen: "review-comparison--frozen",
	    },
		onTop : function() {
			var reviewElem = document.querySelector('.review-header');
			var reviewWrapElem = document.querySelector('.review-header');
			reviewWrapElem.style.minHeight = reviewElem.offsetHeight + 'px';

			var sidebar = document.querySelector(".sidebar");
			sidebar.classList.add("sidebar--fixed-header");
		},
	});

	fixedReviewComparisonHeader.init();
}

