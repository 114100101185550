import Swiper, { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

window.addEventListener("load", function(){
	if ( typeof Swiper !== "undefined" ){
		new Swiper('.partners-swiper', {
			loop: true,
			centeredSlides: true,
			//modules: [Navigation],
			spaceBetween: 10,
			slidesPerView: 2,
			breakpoints: {
				600: {
					slidesPerView: 4,
					spaceBetween: 15
				},
				1000: {
					slidesPerView: 6,
					spaceBetween: 15
				},
			},
		});

		new Swiper('.partners-logo-swiper-left', {
			modules: [Autoplay],
			direction: 'vertical',
			loop: true,
			spaceBetween: 10,
			slidesPerView: 'auto',
			grabCursor: false,
			simulateTouch: false,
			speed: 4000,
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
			}
		});

		new Swiper('.partners-logo-swiper-right', {
			modules: [Autoplay],
			direction: 'vertical',
			loop: true,
			spaceBetween: 10,
			slidesPerView: 'auto',
			grabCursor: false,
			simulateTouch: false,
			speed: 4000,
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
				pauseOnMouseEnter: false,
			}
		});

		new Swiper('.carousel-swiper', {
			modules: [Navigation],
			navigation: {
				nextEl: '.carousel__nav-next',
				prevEl: '.carousel__nav-prev',
			},
			slidesPerView: 1,
			breakpoints: {
				600: {
					slidesPerView: 3,
				},
				1000: {
					slidesPerView: 4,
				},
			},
		});

		new Swiper('.numbered-carousel-swiper', {
			modules: [Navigation],
			navigation: {
				nextEl: '.numbered-carousel__nav-next',
				prevEl: '.numbered-carousel__nav-prev',
			},
			slidesPerView: 1,
			breakpoints: {
				600: {
					slidesPerView: 2,
				},
				1000: {
					slidesPerView: 3,
				},
			},
		});

		for (var i=1;i<=15; i++) {
			new Swiper('.gallery-swiper-' + i, {
				slidesPerView: 1,
				autoHeight: true,
				modules: [Autoplay, Navigation, Pagination],
				navigation: {
					nextEl: '.image-gallery-' + i + '__next',
					prevEl: '.image-gallery-' + i + '__prev',
				},
				pagination: {
					el: '.image-gallery-' + i + '__pagination'
				},
			});
		}
	}
});
