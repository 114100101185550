import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import {delegate} from 'tippy.js';

tippy('[data-tippy-content]', {
	theme: 'usw',
	animation: 'shift-away-subtle',
	// allowHTML: true,
});

delegate('body', {
	target: '[data-tippy-content]',
	theme: 'usw',
	// allowHTML: true,
});