jQuery(document).ready(function($) {
    $(document).on('click', ".load-more a", function (e) {
        e.preventDefault();
        var link = $(this).attr('href');
        $('.load-more a').text('Loading...');

        $.get(link, function(data) {
            var post = $("#posts .card ", data);
            $('#posts').append(post);
        });

        $('.load-more').load(link+' .load-more a');
    });
});