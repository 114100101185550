window.addEventListener('DOMContentLoaded', () => {

	const pluginObserver = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			const id = entry.target.getAttribute('id');
			if (entry.intersectionRatio > 0) {
				var selector = document.querySelector(`.toc li a[href="#${id}"]`);
				if (selector) {
					selector.parentElement.classList.add('is-active');
				}
			} else {
				var selector = document.querySelector(`.toc li a[href="#${id}"]`);
				if (selector) {
					selector.parentElement.classList.remove('is-active');
				}
			}
		});
	});

	// Track all sections that have an `id` applied
	document.querySelectorAll('.prose[id]').forEach((section) => {
		pluginObserver.observe(section);
	});

});

// Close TOC menu when clicking TOC link
(function () {
    const tocLinks = document.querySelectorAll('.toc__link');
    const tocContainer = document.querySelector("#toc");
    const tocBtn = document.querySelector(".toc-btn");
        
    if (tocLinks.length === 0) {
        return false;
    }

    tocLinks.forEach(element => {
        element.addEventListener('click', () => {
            tocContainer.classList.remove('is-toggled');
            tocContainer.setAttribute('aria-hidden', 'true');
            tocBtn.classList.remove('is-toggled');
            tocBtn.setAttribute('aria-expanded', 'false');
        }, false);
    });
}());

